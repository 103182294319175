import { useEffect } from "react";

const DunsSeal = () => {
  useEffect(() => {
    // Load the script dynamically
    const script = document.createElement("script");
    script.src = "https://dunsregistered.dnb.com";
    script.type = "text/javascript";
    script.async = true;
    script.onload = () => console.log("Script loaded successfully");
    script.onerror = () => console.error("Failed to load the script");
    document.body.appendChild(script);

    // Check the current protocol and set the iframe URL
    const url = window.location;
    const iframeSrc = url.protocol === "http:"
      ? "http://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1"
      : "https://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1";

    // Create and append the iframe
    const iframe = document.createElement("iframe");
    iframe.id = "Iframe1";
    iframe.src = iframeSrc;
    iframe.width = "114px";
    iframe.height = "97px";
    iframe.frameBorder = "0";
    iframe.scrolling = "no";
    iframe.allowTransparency = "true";
    iframe.title = "D.U.N.S Seal";

    const dunsSealDiv = document.getElementById("duns-seal");
    if (dunsSealDiv) {
      dunsSealDiv.appendChild(iframe);
    }
  }, []);

  return (
    <div className="duns-container">
      <h5>Dun &amp; Bradstreet</h5>
      <div id="duns-seal" className="duns-seal"></div>
    </div>
  );
};

export default DunsSeal;
