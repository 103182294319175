import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MyAccountMenu from "./MyAccountMenu";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { Helmet } from "react-helmet";
const Myorders = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [loading, setLoading] = useState(true);
  const [myOrder, setMyOrder] = useState([]);
  const [myOrderGrandTotal, setMyOrderGrandTotal] = useState([]);
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const mysOrders = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${apiUrl}/orders`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });

        setMyOrder(response.data.data.orders);
        setMyOrderGrandTotal(response.data.data.grandTotal);
        console.log("myorder Data:", response.data.data.orders);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching best sellers data:", error);
        setLoading(false);
      }
    };

    mysOrders();
  }, []);

  return (
    <div>
      {loading ? <Loader /> : null}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      <section className="cart-detail-sec inner-page-bg">
        <div className="container">
          <div className="row">
            <MyAccountMenu />

            <div
              className="col-lg-9  col-sm-12"
              style={{ alignSelf: "flex-start", marginTop: "17px" }}
            >
              <div className="order-block" style={{ background: "#fff" }}>
                <h2 className="Myorder">My Orders</h2>

                <div
                  className=""
                  style={{ background: "white", paddingLeft: "20px" }}
                >
                  {/* <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}> */}

                  {/* order1 */}
                  <div className="row ">
                    {myOrder.map((myOrderData) => (
                      <div
                        className="py-3"
                        style={{ background: "white", paddingLeft: "20px" }}
                      >
                        {/* <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}> */}
                        <div className="row order1">
                          <div className="col-lg-9 col-sm-12">
                            <div className="orderId">
                              <h3>
                                Order ID :
                                <span>
                                  {myOrderData.id ? myOrderData.id : ""}
                                </span>
                              </h3>
                              <h4 className="cash-delivery">
                                Payment Mode :{" "}
                                {(myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment")
                                  ? "Online Payment"
                                  : myOrderData.payment_method}
                              </h4>
                              {(myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment") && (
                                <h4
                                  className="cash-delivery"
                                  style={{
                                    color: myOrderData.failtransaction_id
                                      ? "red"
                                      : myOrderData.successtransaction_id
                                      ? "green"
                                      : "red", 
                                  }}
                                >
                                  Payment Status :{" "}
                                  {myOrderData.failtransaction_id
                                    ? "Failed"
                                    : myOrderData.successtransaction_id
                                    ? "Success"
                                    : "Cancelled"}
                                </h4>
                              )}

                              <p className="order-date">
                                Order date :{" "}
                                {myOrderData.date_purchased
                                  ? new Date(
                                      myOrderData.date_purchased
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })
                                  : ""}
                              </p>
                              {(myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment") &&
                                myOrderData.failtransaction_id !== null
                                  ? ( <p className="order-date">
                                    Your order has been canceled. This may be due to a payment failure.
                                  </p>)
                                  : (myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment") &&
                                    myOrderData.successtransaction_id === null
                                  ? ( <p className="order-date">
                                   Your order has been canceled. This may be due to a cancellation request from you.
                                  </p>)
                                  : ( <p className="order-date">
                                    Your order is currently being processed. Your
                                    Order Estimate Delivery
                                  </p>)}
                              <div className="totalprice">
                                <h4 className="total">
                                  Total Price :{" "}
                                  <span>
                                    AED{" "}
                                    {myOrderData.order_price
                                      ? myOrderData.order_price
                                      : ""}
                                  </span>
                                </h4>
                                <span className="full-order">
                                  <a
                                    onClick={() => {
                                      nvg("/my-order-detail/" + myOrderData.id);
                                    }}
                                    href="javascript:void(0)"
                                  >
                                    VIEW FULL ORDER
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-3 col-sm-12"
                            style={{ padding: "0px" }}
                          >
                            <div
                              className="rightPart"
                              style={{ height: "230px" }}
                            >
                              {(myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment")? (
                                <>
                                  {myOrderData.status === "Payment Failed" ? (
                                    <img
                                      src="images/circle-cross.png"
                                      alt="Failed"
                                      style={{
                                        verticalAlign: "middle",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  ) : myOrderData.successtransaction_id ? (
                                    <img
                                      src="images/circle-check.png"
                                      alt="Success"
                                      style={{
                                        verticalAlign: "middle",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  ) : myOrderData.successtransaction_id ===
                                    null ? (
                                    <img
                                      src="images/circle-cross.png"
                                      alt="Cancelled"
                                      style={{
                                        verticalAlign: "middle",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="images/circle-cross.png"
                                      alt="Default"
                                      style={{
                                        verticalAlign: "middle",
                                        maxWidth: "100%",
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <img
                                  src="images/circle-check.png"
                                  alt="Cancelled"
                                  style={{
                                    verticalAlign: "middle",
                                    maxWidth: "100%",
                                  }}
                                />
                              )}

                              <h4 className="status-value">
                                {(myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment") &&
                                myOrderData.failtransaction_id !== null
                                  ? "Payment Failed"
                                  : (myOrderData.payment_method === "Paid Online" || myOrderData.payment_method === "Failed Payment") &&
                                    myOrderData.successtransaction_id === null
                                  ? "Cancelled"
                                  : myOrderData.status || ""}
                              </h4>
                              <h5 className="status-label">STATUS</h5>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    ))}
                  </div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Myorders;
