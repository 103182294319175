import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MyAccountMenu from "./MyAccountMenu";
import { getToken } from "../services/LocalStorageSrv";
import axios from "axios";
import Loader from "../Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Magnifier } from "react-image-magnifiers";
import ModalVideo from "react-modal-video";
import ReactPlayer from "react-player";
import "react-modal-video/css/modal-video.min.css";
import { Helmet } from "react-helmet";
const Whishlist = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const cartpopup = useRef(null);
  const quickpopup = useRef(null);
  const quickpopupclose = useRef(null);
  // category filter start here
  const [brand, setbrand] = useState(true);
  const [category, setcategory] = useState(true);
  const [price, setprice] = useState(true);
  const [discount, setdiscount] = useState(false);
  const [filter, setfilter] = useState(false);
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth);

  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [subcategory, setsubcategory] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);
  const [productGalleryData, setProductGalleryData] = useState([]);
  const [GalleryImagePath, setGalleryImagePath] = useState([]);
  const [ProSKU, setProSKU] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const handleMainImageClick = (index) => {
    setMainImageIndex(index);
  };
  const [isOpenV, setOpenV] = useState(false);

  const openVModal = () => {
    setOpenV(true);
  };

  const closeVModal = () => {
    setOpenV(false);
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const nvg = useNavigate();
  const localdata = getToken("data");
  const [loading, setLoading] = useState(true);
  const [mywishlist, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [quantityvalidation, setQuantityValidation] = useState(0);
  const [varient, setvarient] = useState({});
  const [productids, setProductIds] = useState("");
  const [convertedArray, setConvertedArray] = useState([]);
  const [addtocartresponseMessage, setAddCartResponseMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState(null);
  const [totalPrices, setTotalPrices] = useState({});
  const [quantityValues, setQuantityValues] = useState({});
  const [productSKUDetails, setRelatedSKUDetails] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState(Array(20).fill(false)); // Assuming 5 rows
  const [product_de, setProduct_de] = useState(null);
  const [cartproductDetails, setCartProductDetails] = useState(null);

  const handleAddCartAlertClick = () => {
    setError(false);
  };

  const handleQuantityChange = (variantId, quantity, price, pro_id) => {
    varient[variantId] = parseInt(quantity);
    setTimeout(() => {
      setError("");
    }, 1000);
    setProductIds(pro_id);
    // console.log('proid:',pro_id);
    //  console.log('varient detail:',variantId,quantity,varient);
    const newArray = Object.entries(varient)
      .filter(([variant_id, quantity]) => quantity > 0) // Only include entries with quantity > 0
      .map(([variant_id, quantity]) => ({
        variant_id,
        quantity: String(quantity),
      })); // Map to the desired format
    //  console.log("convert to:",newArray);
    setConvertedArray(newArray);
    // console.log('v:',variantId);
    // console.log('p:',price);
    // console.log('q:',quantity);
    // setQuantityValidation(quantity);
    // if (quantity != '' && quantity.length > 1) {
    //   setError('');
    // }else{
    //   setError('Please Select Size and Add Quantity');
    // }
    // if (quantity !== 0) {
    //   setError('');
    // }
    setQuantityValues((prevQuantities) => ({
      ...prevQuantities,
      [variantId]: parseInt(quantity, 10),
    }));
    setTotalPrices((prevTotalPrices) => ({
      ...prevTotalPrices,
      [variantId]: parseFloat(price) * parseInt(quantity, 10),
    }));
  };
  useEffect(() => {
    const initialTotalPrices = {};
    Object.keys(quantityValues).forEach((variantId) => {
      initialTotalPrices[variantId] =
        parseFloat(product_de.uprice) * quantityValues[variantId];
    });
    setTotalPrices(initialTotalPrices);

    const fetchWishlist = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${apiUrl}/wishlist`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProjects(response?.data?.data?.data);
        console.log("Fetched items:", response.data.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchWishlist();
  }, []);

  const handleAlertClick = () => {
    setResponseMessage(false);
  };
  const [responseMessage, setResponseMessage] = useState("");
  const handleDeleteButtonClick = (id) => {
    const collectdata = {
      product_id: id,
    };
    const fetchDeletewishlistProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);
          // setResponseMessage(response.data.message);
          // setTimeout(() => window.location.reload(), 3000);
          // console.log('succeess',response.data.message);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchDeletewishlistProduct();
  };
  const [productDetails, setProductDetails] = useState(null);
  const [productId, setProductId] = useState(null);
  const handleQuickButtonClick = (id) => {
    if (quickpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(quickpopup.current);
      modal.show();
    }
    setProductId(id);
    //  console.log('id:',id);
    const fetchQuickModelValue = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProductDetails(response.data.data);
        setWishlist(response?.data?.data?.wishlist);
        setGalleryImagePath(response.data.data.gallery);
        // console.log('ro n:',response.data.data.gallery);
        let slid_img = [];

        if (response.data.data.rotateImagePath == "") {
          slid_img = [
            response.data.data.main_image,
            ...response.data.data.gallery,
          ];
          setProductGalleryData(slid_img);
          console.log("Q slid  imggg:", slid_img);
        } else {
          slid_img = [
            "360-view",
            response.data.data.main_image,
            ...response.data.data.gallery,
          ];
          setProductGalleryData(slid_img);
          console.log("Q slid 360  imggg:", slid_img);
        }

        setProSKU(response?.data?.data?.sku);
        //   console.log('pro de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchQuickModelValue();
  };
  const handleAddCartButtonClick = (id, name, sku, qyt) => {
    if (quickpopupclose.current) {
      // Trigger the click event on the button
      quickpopupclose.current.click();
    }
    if (cartpopup.current) {
      // Show the modal using Bootstrap's modal API
      const modal = new window.bootstrap.Modal(cartpopup.current);
      modal.show();
    }
    setProductIds(id);
    // console.log('proid:',id);
    const fetchProductValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/products/${id}`, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });
        setProduct_de(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    const fetchAddCartModelValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/products/porductVariant?product_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setCartProductDetails(response.data.data);
        const updatedAttributes = {};
        response.data.data.forEach((item, index) => {
          updatedAttributes[item.id] = 0;
        });
        setvarient((prevData) => ({
          ...prevData,
          ...updatedAttributes,
        }));
        //  console.log('pro cart de:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    const fetchRelatedSKUValue = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiUrl}/getRelatedProductsBySKU?sku=${sku}`,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        setRelatedSKUDetails(response.data.data);
        // console.log('pro cr sku:',response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchRelatedSKUValue();
    fetchProductValue();
    fetchAddCartModelValue();
  };
  // Function to handle form submission
  // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
  // console.log('conv array submit:',convertedArray);

  const handleAddToCartSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple clicks

    setIsSubmitting(true);

    // if (!/^\d+$/.test(quantityvalidation) || quantityvalidation <= 0) {
    //   if(quantityvalidation <= 0){
    //   setError('Please Select Size and Add Quantity.');
    // } else {
    // Perform submit action or other logic here
    // console.log('quantity array:',quantityValues);
    // console.log('price array:',totalPrices);
    // console.log('Submit:', quantityvalidation);
    // }
    // const value = parseInt(quantityvalidation);

    // if (!isNaN(quantityvalidation) && quantityvalidation > 0 && quantityvalidation <= 1) {
    if (!convertedArray[0]) {
      // console.log('cons:',convertedArray);
      setError("Please Select Size and Add Quantity.");
    } else {
      // console.log('proids:',productids);
      // console.log('varient qyt array:',convertedArray);
      // Your submission logic here
      const collectdata = {
        product_id: productids,
        product_option: convertedArray,
      };
      // console.log('add to cart submit:',collectdata);

      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/carts/add`, collectdata, {
          headers: {
            Authorization: `Bearer ${localdata.data.token}`,
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        });

        if (response.data.success === true) {
          // Assuming you have a function like storeToken to handle token storage
          // storeToken(collectdata);

          setAddCartResponseMessage(response.data.message);

          setShowModal(false);
          toast.success(
            <div>
              <div>{response.data.message}</div>
              <button
                className="order-now"
                onClick={redirectToCart}
                style={{ marginTop: "10px" }}
              >
                Go to Cart
              </button>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );

          setTimeout(() => {
            window.location.reload(true);
          }, 2000); // 7000 milliseconds is the autoClose duration
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };
  const redirectToCart = () => {
    // Redirect logic to the cart page; replace with your actual redirect logic
    console.log("Redirecting to the cart page...");
    // For demonstration purposes, let's assume the cart page has a path '/cart'
    // You may want to use react-router-dom or your preferred routing library for this
    // window.location.href = '/cart';
    nvg(`/cart`);
  };

  const handleProDetails = (pro_name) => {
    nvg(`/product-detail/${pro_name}`);
    window.location.reload();
  };

  const [wishlist, setWishlist] = useState([]);

  const handleAddWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistAd = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/addToWishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
          // setProductData(prevItems => Array.isArray(prevItems) ? prevItems.filter(items => items.id !== product_id) : []);

          setWishlist(response.data.success);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistAd();
  };

  const handleRemoveWishlistQuick = async (product_id) => {
    const collectdata = {
      product_id: product_id,
    };
    const fetchwishlistRemove = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}/RemoveFromwishlist`,
          collectdata,
          {
            headers: {
              Authorization: `Bearer ${localdata.data.token}`,
              "Content-Type": "application/json; charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        if (response.data.success === true) {
          toast.success(
            <div>
              <div>{response.data.message}</div>
            </div>,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          setWishlist(false);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
          setLoading(false);
        } else {
          // Handle error, maybe show an error message to the user
          console.error("Error add whislist");
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred while add whislist", error);
        setLoading(false);
      }
    };
    fetchwishlistRemove();
  };
  return (
    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      {loading ? <Loader /> : null}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section id="product-listing-sec" className="inner-page-bg mb-5">
        <div className="container">
          <div className="row">
            <MyAccountMenu />
            <div
              className="col-lg-9  col-sm-12 Myitems-box"
              style={{ alignSelf: "flex-start", marginTop: "18px" }}
            >
              <div className="row">
                <h2 className="myItems">Whishlist</h2>
                {responseMessage && (
                  <div
                    className="alert alert-success"
                    onClick={handleAlertClick}
                  >
                    {responseMessage}
                  </div>
                )}
                {mywishlist?.[0] ? (
                  mywishlist.map((project, index) => (
                    // {mywishlist.map((project,index) => (
                    <div className=" col-lg-4 col-md-6 ">
                      <div className="card rightone">
                        <div className="imageheart">
                          {project.wishlist === true ? (
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                handleDeleteButtonClick(project.id)
                              }
                              className="addToWish"
                            >
                              <img
                                src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                                alt="Wishlist"
                              />
                            </a>
                          ) : (
                            <a href="javascript:void(0);" className="addToWish">
                              <i className="fa fa-heart"></i>
                            </a>
                          )}
                        </div>
                        <a
                          href="javascript:void(0);"
                          onClick={() =>
                            handleAddCartButtonClick(
                              project.id,
                              project.product_name,
                              project.sku,
                              project.cartonQty
                            )
                          }
                        >
                          <img
                            src={project.medium_image}
                            alt={project.product_name}
                          />

                          <div className="style-no">
                            <h6>
                              <span>
                                {project.product_name.length > 30
                                  ? `${project.product_name.substr(0, 33)}...`
                                  : project.product_name}
                              </span>
                            </h6>
                          </div>
                          <div className="Price-box">
                            <p>
                              <span>AED {project.price}</span>
                              {project.regular_price != null ? (
                                <del>
                                  <span>
                                    <small className="text-muted">
                                      &nbsp;&nbsp; AED {project.regular_price}
                                    </small>
                                  </span>
                                </del>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="Order-price">
                            <span>Ordered Price : AED {project.price}</span>
                          </div>

                          <div class="product-sku">
                            <p>
                              {" "}
                              UNIT:
                              <span>
                                {" "}
                                {project.product_unit
                                  ? project.product_unit
                                  : "Pcs"}{" "}
                              </span>
                            </p>
                            <p>
                              {" "}
                              SKU:
                              <span> {project.sku} </span>
                            </p>
                          </div>
                        </a>

                        {/* <div className="card-body">
                                 <h5 className="card-title">A</h5>
                                 <p className="card-text">b</p>
                                 <p className="card-text">c<small className="text-muted">d</small></p>
                              </div> */}
                        <div className=" lastbutton">
                          <button
                            className="btn btn-primary one"
                            type="button"
                            style={{ display: "flex" }}
                          >
                            {" "}
                            <span>
                              <i className="fa-regular fa-eye" />
                            </span>{" "}
                            <span
                              onClick={() => handleQuickButtonClick(project.id)}
                            >
                              QUICK VIEW
                            </span>
                          </button>
                          <button
                            className="btn btn-primary two"
                            type="button"
                            onClick={() => {
                              nvg("/product-detail/" + project.slug);
                            }}
                          >
                            DETAILS VIEW
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className=" col-12 ">
                    Your wishlist is empty. Start adding products you love!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* start quick model popup */}
      <div
        className="modal fade"
        ref={quickpopup}
        id="quickViewModel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            {/* <div className="modal-header" style={{ borderBottom: "none" }}> */}

            {/* </div> */}
            <div className="modal-body">
              <button
                ref={quickpopupclose}
                type="button"
                className="btn-close remove-button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  background: " #059fe2",
                  color: "#fff",
                  borderRadius: "0px",
                  fontSize: "20px",
                }}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
              <div className="container">
                <div className="row mb-5">
                  <div className="col-lg-6" style={{ marginTop: "20px" }}>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 slider-part">
                        <div className="flexslider galleries">
                          <div>
                            <div className="card-carousel gallery-active">
                              <div
                                className="thumbnails"
                                style={{ position: "relative" }}
                              >
                                {productGalleryData == null ? (
                                  <Carousel
                                    showArrows={false}
                                    showIndicators={false}
                                    showStatus={false}
                                    autoPlay={false}
                                  >
                                    <img
                                      src={productDetails.main_image}
                                      className="slider_img_border"
                                      style={{ borderColor: "#059fe2" }}
                                    />
                                  </Carousel>
                                ) : (
                                  <Carousel
                                    showArrows={false}
                                    showIndicators={false}
                                    showStatus={false}
                                    autoPlay={true}
                                    interval={3000}
                                  >
                                    {productGalleryData.map((image, index) =>
                                      image == "360-view" ? (
                                        <>
                                          <iframe
                                            id="ifreamClass"
                                            src={`${process.env.REACT_APP_360_URL}/${ProSKU}/edit.html`}
                                            width="600"
                                            height="600"
                                          ></iframe>
                                          <img
                                            src={`${process.env.REACT_APP_360_URL}/${ProSKU}/images/img_0_0_0.jpg`}
                                            className="slider_img_border"
                                          />
                                        </>
                                      ) : (
                                        <img
                                          key={index}
                                          src={image}
                                          className="slider_img_border"
                                        />
                                      )
                                    )}
                                  </Carousel>
                                )}

                                {productDetails && productDetails.video ? (
                                  <div
                                    className="thumbnail-image_quick"
                                    style={{ marginTop: "115px" }}
                                  >
                                    <video
                                      href="javascript:void(0);"
                                      className="slider-img-pro-detail_quick"
                                      role="button"
                                      onClick={openVModal}
                                    >
                                      <source
                                        src={productDetails.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-sm-12"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="row">
                      <h2 className="popup-text text-uppercase">
                        {productDetails && productDetails.product_name
                          ? productDetails.product_name
                          : ""}
                      </h2>
                      <p className="Sku-text">
                        SKU:{" "}
                        {productDetails && productDetails.sku
                          ? productDetails.sku
                          : ""}
                      </p>

                      <div className="action action_border">
                        <a href="#" className="share1">
                          <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/share.png`}
                            alt="share"
                          />
                          <p className="share-img">Share</p>
                          <span
                            style={{
                              background: "#e9e9e9",
                              height: "45px",
                              content: "",
                              width: "1px",
                              position: "absolute",
                              right: "0",
                              top: "25px",
                            }}
                          ></span>
                        </a>
                        {/* {productDetails && productDetails.wishlist === true ? (
                        <a href="#" className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </a>
                        ) : (
                        <div className="wish">
                           <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`} alt="whishlist" />
                           <p className="share-img">Wish</p>
                        </div>
                        )} */}
                        {wishlist === false ? (
                          <a
                            onClick={() =>
                              handleAddWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/wish_list.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        ) : (
                          <a
                            onClick={() =>
                              handleRemoveWishlistQuick(productDetails.id)
                            }
                            href="javascript:void(0);"
                            className="whish"
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icons/Whishlist.png`}
                            />
                            <p className="share-img">Wish</p>
                          </a>
                        )}
                      </div>

                      <div className="price1">
                        <label
                          htmlFor=""
                          style={{
                            color: "#000",
                            fontWeight: "550",
                            fontSize: "17px",
                          }}
                        >
                          Price:
                        </label>
                        <p>
                          <span style={{ fontWeight: "500" }}>
                            AED{" "}
                            {productDetails && productDetails.uprice
                              ? productDetails.uprice
                              : ""}
                          </span>
                        </p>
                        <span className="smallaed">
                          AED{" "}
                          {productDetails && productDetails.regular_price
                            ? productDetails.regular_price
                            : ""}
                        </span>
                      </div>
                      {productDetails && productDetails.shortFeature ? (
                        <div className="product-detail">
                          <div>
                            <h4
                              style={{
                                color: "#000",
                                fontWeight: "500",
                                fontSize: "17px",
                                lineHeight: "35px",
                              }}
                              className="text-uppercase label"
                            >
                              <b> Features: </b>
                              {/* Features: */}
                            </h4>
                            <div>
                              <div
                                className="mb-2"
                                style={{ color: "#000" }}
                                dangerouslySetInnerHTML={{
                                  __html: productDetails.shortFeature,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "#565656",
                                fontSize: "18px",
                                lineHeight: "24px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: productDetails.short_description,
                              }}
                            ></p>
                          </div>
                          <br />
                          <div className="read-more-btn">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                handleProDetails(productDetails.slug)
                              }
                              className=""
                              style={{ color: "#007bff" }}
                            >
                              READ MORE
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="order-now-btn">
                        <p>
                          <button
                            onClick={() =>
                              handleAddCartButtonClick(
                                productDetails.id,
                                productDetails.product_name,
                                productDetails.sku,
                                productDetails.cartonQty
                              )
                            }
                          >
                            Order Now
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end quick model popup */}

      {/* add to cart model popup page start */}
      {showModal ? (
        <div
          className="modal fade"
          id="addToCartP"
          ref={cartpopup}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="addToCartPLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close remove-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    background: " #059fe2",
                    color: "#fff",
                    borderRadius: "0px",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
              <form onSubmit={handleAddToCartSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="modal-title text-center pb-1 label">
                        {" "}
                        <strong>PLEASE CONFIRM ORDER VARIANT & QUANTITY</strong>
                      </h1>
                      <p className="text-center pb-2">
                        {" "}
                        {product_de && product_de.product_name
                          ? product_de.product_name
                          : ""}
                      </p>

                      <div className="modal-body">
                        <ul className="list-inline">
                          <li className="list-inline-item pl-2 pb-2 mr-4">
                            Item Group :{" "}
                            {product_de && product_de.sku ? product_de.sku : ""}{" "}
                          </li>
                          <li className="list-inline-item">
                            {" "}
                            Carton Qty :{" "}
                            {product_de && product_de.cartonQty
                              ? product_de.cartonQty
                              : "0"}{" "}
                          </li>
                        </ul>

                        {error && (
                          <div
                            className="alert alert-error"
                            onClick={handleAddCartAlertClick}
                          >
                            {error ? error : ""}
                          </div>
                        )}
                        <div className="row">
                          <div className="col-10">
                            <table className="table">
                              <thead>
                                <tr className="label_tabel">
                                  <th scope="col"> Variant </th>
                                  <th scope="col"> Quantity </th>
                                  <th scope="col"> Price </th>
                                  <th scope="col"> Total Price </th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartproductDetails?.[0]
                                  ? cartproductDetails.map(
                                      (variant_info, index) => (
                                        <tr
                                          id={`mp-size-${index}`}
                                          className={`outofstock${variant_info.stock}`}
                                          key={index}
                                        >
                                          <td scope="size">
                                            <div className="checkbox-col">
                                              <input
                                                type="checkbox"
                                                checked={checkboxStates[index]}
                                                onChange={() => {}}
                                                id={`size-v-${index}`}
                                                name={`quantity[${variant_info.id}]`}
                                                value={variant_info.id}
                                              />
                                              <label
                                                htmlFor={`size-v-${index}`}
                                                className="checkbox-label"
                                              >
                                                &nbsp;
                                                {typeof variant_info.item_code ===
                                                "string"
                                                  ? variant_info.item_code.split(
                                                      "-"
                                                    )[1]
                                                  : ""}
                                              </label>
                                            </div>
                                          </td>

                                          {variant_info.stock > 0 ? (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                id={
                                                  product_de &&
                                                  product_de.uprice
                                                    ? product_de.uprice
                                                    : "0.00"
                                                }
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    variant_info.id,
                                                    e.target.value,
                                                    e.target.id,
                                                    product_de.id
                                                  )
                                                }
                                             
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder="0"
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn"
                                              />
                                            </td>
                                          ) : (
                                            <td className="qty">
                                              <input
                                                type="number"
                                                name={`quantity[${variant_info.id}]`}
                                                placeholder={0}
                                                min="0"
                                                max={
                                                  variant_info.stock
                                                    ? variant_info.stock
                                                    : "0"
                                                }
                                                className="qtyIn disable_qyt"
                                                disabled
                                              />
                                            </td>
                                          )}

                                          <td className="label_addtocart_table">
                                            {" "}
                                            AED{" "}
                                            {product_de && product_de.uprice
                                              ? product_de.uprice
                                              : "0.00"}
                                          </td>
                                          <td className="label_addtocart_table">
                                            AED{" "}
                                            {totalPrices[variant_info.id] ||
                                              "0.00"}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  : ""}
                              </tbody>
                            </table>
                          </div>

                          {productSKUDetails !== null ? (
                            <div className="col-2">
                              <table className="table">
                                <thead>
                                  <tr className="label_tabel">
                                    <th scope="col">Related SKU</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {productSKUDetails?.[0] ? (
                                    productSKUDetails.map((sku_info) => (
                                      <tr
                                        className="subtotal"
                                        key={sku_info.sku}
                                      >
                                        <td>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              handleProDetails(sku_info.slug)
                                            }
                                            className=""
                                            style={{
                                              fontWeight: "400",
                                              color: "#007bff",
                                            }}
                                          >
                                            {sku_info.sku}
                                          </a>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td>No related SKUs found.</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="form-group form-row d-flex justify-content-center mt-2 mb-3">
                        <button
                          type="submit"
                          className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2"
                        >
                          Confirm Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* End add to cart model popup */}

      <Footer />
    </div>
  );
};
export default Whishlist;
