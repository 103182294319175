import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Home from "./Pages/Home";
import BestSellers from "./Pages/BestSellers";
import Bulkorder from "./Pages/BulkOrder";
import ECatalogue from "./Pages/ECatalogue";
import NewProducts from "./Pages/NewProducts";
import Offers from "./Pages/Offers";
import OurBrand from "./Pages/OurBrand";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsConditions from "./Pages/TermsConditions";
import TermsSales from "./Pages/TermsSales";
import Blog from "./Pages/Blog";
import Address from "./Pages/Address";
import MyItems from "./Pages/MyItems";
import Whishlist from "./Pages/Whishlist";
import Password from "./Pages/Password";
import Rfq from "./Pages/Rfq";
import Profile from "./Pages/Profile";
import UpdateProfileRequest from "./Pages/UpdateProfileRequest";
import Invoice from "./Pages/Invoice";
import Cart from "./Pages/Cart";
import Login from "./Pages/Login";
import Myorders from "./Pages/Myorders";
import MyorderDetail from "./Pages/MyorderDetail";
import Checkout from "./Pages/Checkout";
import Categorylist from "./Pages/Categorylist";
import ProductDetail from "./Pages/ProductDetail";
import BlogDetail from "./Pages/BlogDetail";
import DownloadVideo from "./Pages/DownloadVideo";
import Notification from "./Pages/Notification";
import ProductCategory from "./Pages/ProductCategory";
import BrandProduct from "./Pages/BrandProduct";
import Shop from "./Pages/Shop";
import ThankuOrder from "./Pages/ThankuOrder";
import ShopNow from "./Pages/ShopNow";
import ResetPassword from "./Pages/ResetPassword";
import CancelOrder from "./Pages/CancelOrder";
import ReturnPolicy from "./Pages/ReturnPolicy";
import CancellationPolicy from "./Pages/CancellationPolicy";
import Aboutus from "./Pages/Aboutus";
import ShippingPolicy from "./Pages/ShippingPolicy";
import Demo from "./Pages/demo";
import { useEffect, useRef, useState } from "react";
import { getToken, removeToken } from "./services/LocalStorageSrv";
import { useNavigate } from "react-router-dom";
// import Loading from './Loading';

function App() {
  // 180000
  const [timeoutDuration, setTimeoutDuration] = useState(180000); // Initial duration: 3 minutes
  const loginpop = useRef(null);
  const newpop = useRef(null);
  const newpop2 = useRef(null);
  const signup = useRef(null);
  const matchotps = useRef(null);
  const forgotpotp = useRef(null);
  const location = useLocation();
  const newpop3 = useRef(null);
  const emailmsg = useRef(null);
  const fromemailnextpopup = useRef(null);
  const token = getToken("data");
  const [timer, setTimer] = useState(null);
  const nvg = useNavigate();
  // 3 min logout code start
  const resetTimer = () => {
    // console.log("eeeeeeee",timer)
    // if (timer) {
    //   clearTimeout(timer);
    // }
    // Set a new timer for 3 minutes
    setTimeout(() => {
      // Perform your desired function after 3 minutes of inactivity
      handleInactivityNotification();
    }, 1 * 60 * 1000); // 3 minutes in milliseconds
  };

  const handleInactivityNotification = () => {
    // Perform your desired action
    console.log("remove token");
    removeToken("data");
    window.location.reload();
    nvg("/");
  };
  // 3 min logout code end
  const handleClickAnywhere = (event) => {
    if (loginpop.current && loginpop.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (newpop.current && newpop.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (newpop2.current && newpop2.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (signup.current && signup.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (matchotps.current && matchotps.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (emailmsg.current && emailmsg.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    if (forgotpotp.current && forgotpotp.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }

    if (newpop3.current && newpop3.current.contains(event.target)) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }

    if (
      fromemailnextpopup.current &&
      fromemailnextpopup.current.contains(event.target)
    ) {
      // Click occurred inside the login pop-up, do nothing
      return;
    }
    // Your custom logic goes here
    // console.log("Clicked anywhere on the website");

    // Check if the modal instance exists before attempting to show it
    // if (loginpop.current) {
    //   // Show the modal using Bootstrap's modal API
    //   const modal = new window.bootstrap.Modal(loginpop.current);
    //   modal.show();
    // }
    if (location.pathname.includes("reset-password")) {
      console.log("not exist found");
    } else {
      if (loginpop.current) {
        // Show the modal using Bootstrap's modal API
        const modal = new window.bootstrap.Modal(loginpop.current);
        modal.show();
      }
    }
  };
  // window.addEventListener('mouseover', resetTimer);
  // window.addEventListener('keypress', resetTimer);
  //     window.removeEventListener('scroll', resetTimer);
  //     window.removeEventListener('click', resetTimer);
  useEffect(() => {
    if (token == null) {
      const handleClick = (event) => {
        // Call the custom function when a click is detected
        handleClickAnywhere(event);
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    } else {
      const timeoutId = setTimeout(() => {
        // Your logic after the timeout
        handleInactivityNotification();
        console.log("Timeout completed!");
      }, timeoutDuration);

      // Remove existing event listeners
      window.removeEventListener("mousemove", handleResetTimer);
      window.removeEventListener("keypress", handleResetTimer);
      window.removeEventListener("scroll", handleResetTimer);
      window.removeEventListener("click", handleResetTimer);

      // Add new event listeners with the updated timeout duration
      window.addEventListener("mousemove", handleResetTimer);
      window.addEventListener("keypress", handleResetTimer);
      window.addEventListener("scroll", handleResetTimer);
      window.addEventListener("click", handleResetTimer);

      return () => clearTimeout(timeoutId);
      //  return () => {
      //   window.removeEventListener('mousemove', resetTimer());
      //   window.removeEventListener('keypress', resetTimer());
      //   window.removeEventListener('scroll', resetTimer());
      //   window.removeEventListener('click', resetTimer());
      // };
    }
    // else{
    //   const handleActivity = () => {
    //     resetTimer();
    //   };

    //   // Attach event listeners for user activity (e.g., mouse move, key press)
    //   window.addEventListener('mousemove', handleActivity);
    //   window.addEventListener('keypress', handleActivity);
    //   window.removeEventListener('scroll', handleActivity);
    //   window.removeEventListener('click', handleActivity);

    //   // Initial setup
    //   resetTimer();

    //   // Cleanup event listeners on component unmount
    // return () => {
    //   window.removeEventListener('mousemove', handleActivity);
    //   window.removeEventListener('keypress', handleActivity);
    //   window.removeEventListener('scroll', handleActivity);
    //   window.removeEventListener('click', handleActivity);
    // };
    // }
  }, [timeoutDuration]);

  const handleResetTimer = () => {
    // Reset the timeout duration to 8 minutes (480000 milliseconds) on any action
    setTimeoutDuration(480000);
  };
  return (
    <div className="App">
      {/* <Loading /> */}
      <Header
        loginpopRef={loginpop}
        newpop={newpop}
        newpop2={newpop2}
        newpop3={newpop3}
        signup={signup}
        matchotps={matchotps}
        emailmsg={emailmsg}
        fromemailnextpopup={fromemailnextpopup}
      />

      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/best-seller"
          element={token == null ? <Home /> : <BestSellers />}
        />
        <Route
          path="/bulk-order"
          element={token == null ? <Home /> : <Bulkorder />}
        />
        <Route
          path="/ecatalogue"
          element={token == null ? <Home /> : <ECatalogue />}
        />
        <Route
          path="/new-products"
          element={token == null ? <Home /> : <NewProducts />}
        />
        <Route path="/offers" element={token == null ? <Home /> : <Offers />} />
        <Route
          path="/brand"
          element={token == null ? <Home /> : <OurBrand />}
        />
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/Terms-Conditions" element={<TermsConditions />} />
        <Route path="/Terms-Sales" element={<TermsSales />} />
        <Route path="/Return-Policy" element={<ReturnPolicy />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/Shipping-Policy" element={<ShippingPolicy />} />
        <Route path="/Cancellation-Policy" element={<CancellationPolicy />} />
        <Route
          path="/product-category"
          element={token == null ? <Home /> : <Categorylist />}
        />
        <Route path="/blog" element={token == null ? <Home /> : <Blog />} />
        <Route
          path="/product-detail/:name"
          element={token == null ? <Home /> : <ProductDetail />}
        />
        <Route
          path="/blog-detail/:name"
          element={token == null ? <Home /> : <BlogDetail />}
        />
        <Route
          path="/address"
          element={token == null ? <Home /> : <Address />}
        />
        <Route
          path="/checkout"
          element={token == null ? <Home /> : <Checkout />}
        />
        <Route
          path="/myitems"
          element={token == null ? <Home /> : <MyItems />}
        />
        <Route
          path="/whishlist"
          element={token == null ? <Home /> : <Whishlist />}
        />
        <Route
          path="/password"
          element={token == null ? <Home /> : <Password />}
        />
        <Route path="/rfq" element={token == null ? <Home /> : <Rfq />} />
        <Route
          path="/profile"
          element={token == null ? <Home /> : <Profile />}
        />
        <Route
          path="/update-profile"
          element={token == null ? <Home /> : <UpdateProfileRequest />}
        />
        <Route
          path="/invoice/*"
          element={token == null ? <Home /> : <Invoice />}
        />
        <Route path="/cart" element={token == null ? <Home /> : <Cart />} />
        <Route path="/login" element={token == null ? <Home /> : <Login />} />
        <Route
          path="/myorders"
          element={token == null ? <Home /> : <Myorders />}
        />
        <Route
          path="/my-order-detail/:id"
          element={token == null ? <Home /> : <MyorderDetail />}
        />
        <Route
          path="/download-video"
          element={token == null ? <Home /> : <DownloadVideo />}
        />
        <Route
          path="/notification"
          element={token == null ? <Home /> : <Notification />}
        />
        <Route
          path="/product-category-detail"
          element={token == null ? <Home /> : <ProductCategory />}
        />
        <Route
          path="/brand-product/:id"
          element={token == null ? <Home /> : <BrandProduct />}
        />
        <Route path="/shop" element={token == null ? <Home /> : <Shop />} />
        <Route
          path="/thank-you/:id"
          element={token == null ? <Home /> : <ThankuOrder />}
        />
        <Route
          path="/shop-now"
          element={token == null ? <Home /> : <ShopNow />}
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/cancel-order" element={<CancelOrder />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </div>
  );
}

export default App;
