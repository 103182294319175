import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
// import Loader from '../Loader';
import { Helmet } from "react-helmet";
const TermsConditions = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);
  // const [loading, setLoading] = useState(true);
  return (
    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      {/* {loading ? <Loader /> : null} */}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="bulkorder-heading text-center mt-5">
              <h3> Terms & Conditions </h3>
            </div>
            <div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para">
              <p className="bold"> Last Updated: 01.01.2023</p>
              <p>
                Welcome to SBM Market Place ("us," "we," or "our"). These Terms
                and Conditions ("Terms") govern your use of the website
                www.sbmmarketplace.com (the "Website"). Please read these Terms
                carefully before using our Website. By accessing or using our
                Website, you agree to be bound by these Terms. If you do not
                agree with these Terms, please do not use the Website.
              </p>
              <p style={{ paddingTop: "5px" }}>Use of the Website</p>
              <p style={{ paddingTop: "15px" }}>1.1. Eligibility:</p>
              <p>
                You must be at least 18 years old to use our Website. By using
                the Website, you represent and warrant that you are at least 18
                years old and have the legal capacity to enter into these Terms.
              </p>
              <p>1.2. Account Registration:</p>
              <p>
                To access certain features of the Website, you may need to
                create an account. You agree to provide accurate, current, and
                complete information during the registration process and to keep
                your account information updated. You are responsible for
                maintaining the confidentiality of your account credentials and
                for all activities that occur under your account. You must
                notify us immediately of any unauthorized use of your account.
              </p>
              <p>1.3. Prohibited Activities:</p>
              <p>You agree not to:</p>
              <p>
                Use the Website for any illegal, unauthorized, or fraudulent
                purposes.Interfere with the proper functioning of the Website or
                attempt to gain unauthorized access to any portion or feature of
              </p>
              <p>the Website.</p>
              <p>
                Upload or transmit any viruses, malware, or other malicious
                code.
              </p>

              <p>
                Violate any applicable laws, regulations, or third-party rights.
              </p>

              <p>
                Engage in any activity that may harm or disrupt the Website or
                our services.
              </p>

              <p style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                Product Listings and Orders
              </p>

              <p>2.1. Product Information:</p>
              <p>
                We strive to provide accurate and up-to-date product information
                on our Website. However, we do not warrant that the product
                descriptions, images, pricing, or other content on the Website
                are complete, reliable, or error-free. We reserve the right to
                correct any errors, inaccuracies, or omissions and to change or
                update information at any time without prior
              </p>
              <p>notice.</p>
              <p>2.2. Orders and Payments:</p>
              <p>
                By placing an order through our Website, you make an offer to
                purchase the products or services selected. We reserve the right
                to accept or reject any order at our discretion. Upon placing an
                order, you agree to provide accurate and complete payment
                information. You authorize us to charge the applicable fees to
                your selected payment method. All payments are subject to
                verification and acceptance by us and may be refunded or
                canceled if deemed necessary.
              </p>
              <p>2.3. Shipping and Delivery:</p>
              <p>
                Shipping and delivery times may vary based on factors beyond our
                control. We will make reasonable efforts to fulfill and deliver
                your orders promptly. However, we do not guarantee specific
                delivery dates or times. Risk of loss and title for products
                pass to you upon our delivery to the carrier.Intellectual
                Property
              </p>
              <p style={{ paddingTop: "15px" }}>3.1. Ownership:</p>
              <p>
                All content and materials available on the Website, including
                but not limited to text, graphics, logos, images, audio clips,
                videos, and software, are the property of SBM Market Place or
                its licensors and are protected by applicable intellectual
                property laws. You may not use, reproduce, modify, distribute,
                or display any portion of the Website without our prior
              </p>
              <p>written consent.</p>
              <p>3.2. Trademarks:</p>
              <p>
                SBM Market Place, our logo, and other trademarks, service marks,
                or trade names used on the Website are owned by SBM Market
                Place. You may not use any of these marks without our prior
                written permission.Disclaimers and Limitations of Liability
              </p>
              <p style={{ paddingTop: "15px" }}>
                4.1. Disclaimer of Warranties:
              </p>
              <p>
                The Website is provided on an "as is" and "as available" basis
                without warranties of any kind, whether express or implied. We
                do not guarantee the accuracy, reliability, or completeness of
                the Website's content or the availability, security, or
                functionality of the Website. You use the Website at your own
                risk.
              </p>
              <p>4.2. Limitation of Liability:</p>
              <p>
                To the extent permitted by applicable laws, we shall not be
                liable for any direct, indirect, incidental, consequential, or
                punitive damages arising out of or in connection with your use
                of the Website or any products or services obtained through the
                Website. Our total liability, whether in contract, tort, or
                otherwise, shall not exceed the amount you paid to us, if any,
                for accessing the Website.
              </p>
              <p>Indemnification</p>
              <p>
                You agree to indemnify, defend, and hold harmless SBM Market
                Place and its officers, directors, employees, agents, and
                affiliates from and against any claims, liabilities, damages,
                losses, costs, or expenses, including reasonable attorneys'
                fees, arising out of or in connection with your use of the
                Website, violation of these Terms, or any violation of
                applicable
              </p>
              <p>laws or third-party rights.</p>
              <p>Governing Law and Jurisdiction:</p>
              <p>
                 Any purchase, dispute or
                claim arising out of or in connection with this website shall be
                governed and construed in accordance with the laws of UAE.
              </p>
           
              <p>Changes to the Terms</p>
              <p>
                We reserve the right to update or modify these Terms at any time
                without prior notice. Any changes will be effective upon posting
                the revised version on the Website. Your continued use of the
                Website after any changes to the Terms
              </p>
              <p>constitutes your acceptance of such changes.</p>
              <p>Contact Us</p>
              <p>
                If you have any questions, concerns, or suggestions regarding
                these Terms, please contact us at
              </p>
              <a href="" style={{ color: "blue" }}>
                app@sbmmarketplace.com
              </a>
              <p>Thank you for using SBM Market Place!</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsConditions;
