import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
// import Loader from '../Loader';
const ReturnPolicy = () => {
  //   const [showsidebar, setshowsidebar] = useState(false);

  return (
    <div>
      {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}
      <Helmet>
        <title>{`Home | Vaultex`}</title>
      </Helmet>
      <section class="bulkorder-sec pb-5 mt-5">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="bulkorder-heading text-center mt-5">
              <h3> Shipping Policy </h3>
            </div>
            <div className="row w-75 ml-auto mr-auto mt-4 ct_privacy_para">
              <div className="privacysmalltext">
                <p className="bold">PICKUP</p>
                <p className="bold">
                  Free in-store pickup is available at our local warehouses
                  (Monday – Saturday, 8:30AM – 6:30PM).{" "}
                </p>
              </div>
              <div className="privacysmalltext">
                <p className="bold">Your order will be ready for pickup</p>
              </div>

              <div className="privacysmalltext">
                <p>
                  On the same day provided stock available at the designated
                  warehouse In case of shortage material, orders will be ready
                  within 3 -4 business days (Consult your designated salesperson
                  for the readiness of your materials.)
                </p>
              </div>

              <div className="privacysmalltext">
                <p className="bold">DELIVERY</p>
                <p>
                  You are eligible for Free shipping across the UAE in our
                  existing service areas (no minimum order amount)
                </p>
              </div>

              <div className="privacysmalltext">
                <p>
                  For deliveries out of our service areas orders will be
                  delivered via courier based on the below criteria’s{" "}
                </p>
                <p>
                  {" "}
                  * Orders above 1000 AED will be eligible for Free Shipping
                </p>
                <p>
                  {" "}
                  * Orders below 1000 AED will not be eligible for Free Shipping
                  (Courier charges applicable)
                </p>
              </div>

              <div className="privacysmall">
                <p className="bold">
                  {" "}
                  Orders will be dispatched based on below criteria
                </p>
                <p>
                  {" "}
                  1.If all stocks are available at the designated warehouse
                  order will be dispatched the next day (within 24 hours)
                </p>
                <p>
                  {" "}
                  2.In case of shortage material, orders will be dispatched
                  within 3-4 business days(Consult your designated salesperson
                  or our office for better assistance.)
                </p>
                <p>
                  Additional shipping charges will be applied for all
                  international orders. (Consult your designated salesperson or
                  our office for better assistance)
                </p>
              </div>

              <div className="privacysmall">
                <p className="bold">
                  {" "}
                  www.sbmmarketplace.com will NOT deal or provide any services
                  or products to any of OFAC (Office of Foreign Assets Control)
                  sanctions countries in accordance with the law of UAE.
                </p>
              </div>

              <div className="privacysmall">
                <p className="bold">Souvenir Building Materials LLC </p>
                <p>Address:</p>
                <p>Street # 21C, Yousuf Baker Road – Dubai</p>
                <p>Phone: 04 224 1807</p>
                <p>Care@sbmmarketplace.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ReturnPolicy;
