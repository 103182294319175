import React, { useEffect, useState } from "react";
// import Header from '.../components/Header/Header.css';
import Footer from '../components/Footer';
// import Header from '../components/Header';
const Login = () => {
 

  return (
    <div>
      {/* <Header /> */}

      <div>



        {/* <div className="row ">
    <div className="col-12">
      <div className="form-group form-row">
        <input id="email" type="text" name="email" placeholder="Email address or Vendor code*" className="form-control input-area" />
      </div>
    </div>
    <div className="col-12">
      <div className="form-group form-row password-key-group" >
     
        <input v-if="showPassword" type="text" className="input form-control input-area" v-model="password" id="password" placeholder="Password*" />
        <input v-else type="password" className="input form-control input-area" v-model="password" id="password" placeholder="Password*" />
        <span className="icon is-small is-right" >
          <i className="fa"/>
        </span>
       
      </div>
    </div>
  </div>
  <div className="row mt-3 mb-4">
    <div className="col-12">
      <button v-if="flag == 1" disabled="disabled" type="submit" className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2">
        Continue
      </button>
      <button v-if="flag == 0" type="submit" className="btn btn-signup col-12 rounded-0 text-uppercase pt-2 pb-2">
        Continue
      </button>
    </div>
  </div> */}


        <div>
          {/* Button trigger modal */}
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Login
          </button>
          {/* Modal */}
          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header" style={{ borderBottom: 'none' }}>
                  {/* <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5> */}
                  <button type="button" className="btn-close remove-button" data-bs-dismiss="modal" aria-label="Close" style={{ background: ' #059fe2', color: '#fff', borderRadius: '0px', fontSize: '20px' }}> <i class="fa-solid fa-xmark"></i></button>

                </div>
                <div className="modal-body">
                  <h5 className="sign-text  text-center mt-1 pb-4">Access Request</h5>

                  <div className="form-group form-row d-flex justify-content-center" style={{display:'flex'}}>
                    <div className="col-4" style={{marginLeft:'12px'}}>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1"  style={{position:'absolute',top:'71px'}}/>
                        <label className="form-check-label" htmlFor="inlineRadio1" >Mobile</label>
                      </div>
                    </div>

                    <div className="col-4" style={{}}>
                      <center>OR</center>
                    </div>



                    <div className="col-4" style={{marginLeft:'30px'}}>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1"  style={{position:'absolute',top:'71px'}}/>
                        <label className="form-check-label" htmlFor="inlineRadio1" >Email</label>
                      </div>
                    </div>


                    {/* <div className="form-group form-row d-flex justify-content-center" >
                      <input id="email" type="text" name="email" placeholder="Email address or Vendor code*" className="input-areabutton" style={{ background: '#efefef' }} />
                    </div> */}


                    {/* <div className="form-group form-row d-flex justify-content-center mt-3">
                      <input id="Password" type="text" name="Password" placeholder="Password*" className="input-areabutton" style={{ background: '#efefef' }} />
                      <span className="icon is-small is-right"><i className="fa fa-eye-slash"></i></span>
                    </div> */}
                  </div>

                  <div className="form-group form-row d-flex justify-content-center mt-3">
        <input id="input" type="text" name="input" placeholder="Enter Code*" className="input-areabutton"  />
      
      </div>
        </div>
        
                  <div className="form-group form-row d-flex justify-content-center mt-2 mb-4">
                    <button type="submit" className="btn btn-signup col-9 rounded-0 text-uppercase pt-2 pb-2">
                      Continue
                    </button>
                  </div>
                  <div className="col-md-12 col-12">
                    <div className="row" style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', }}>
                      <div className="forgot text-left col-6 mb-5 mt-3">
                        <a href="" className="active">Retrive Access</a>

                      </div>
                      <div className="forgot1 text-right col-6 mb-5 mt-3">
                        <a href="">Forgot Password ?</a>
                      </div>

                    </div>
                  </div>

                  <div className="signin-footer pt-3 pb-3 mb-3">
                    <div className="signup-text text-center">
                      <h6>Dont't have an account ?
                        <a href="" className="active">SIGN UP NOW</a>
                      </h6>
                      <span>By signing up your agree to our <a href="">Terms of use</a></span>
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
        </div>














        <Footer />



      </div>
    //   </div>

  )
}
export default Login;